import { InAppPurchase } from "@ionic-native/in-app-purchase";

import { defaultClient } from "@/main";
import validateSubscriptionMutation from "@/graphql/validateSubscription.mutation.graphql";
import processSubscriptionMutation from "@/graphql/processSubscription.mutation.graphql";

import { Capacitor } from "@capacitor/core";
import { SubscriptionTransaction, Response } from "./types";

import { InAppPurchase as store } from "@ionic-native/in-app-purchase";

const PRODUCT_ID = "1_month";

export const isSandboxEnvironment = async (): Promise<boolean> => {
    const platform = Capacitor.getPlatform();

    if (platform === "ios") {
        const receipt = await store.getReceipt();
        // Check if the receipt contains 'sandbox'
        if (receipt) {
            return receipt.includes("sandbox");
        }
    } else if (platform === "android") {
        const purchases = await store.restorePurchases();
        // Check if any of the purchases are in the sandbox environment
        if (purchases && purchases.length > 0) {
            const sandboxPurchase = purchases.find((purchase) => purchase.developerPayload.includes("sandbox"));
            return !!sandboxPurchase;
        }
    }

    return false;
};


export const initializeStore = async () => {
    const isSandbox = await isSandboxEnvironment();
    console.log('isSandbox', isSandbox)
    try {
        const products = await store.getProducts([PRODUCT_ID]);
        console.log("Products:", products);
        return products;
    } catch (err) {
        console.error("Error getting products:", err);
    }
};

export const storeBuySubscription =
    async (): Promise<SubscriptionTransaction> => {
        try {
            const transaction = await store.subscribe(PRODUCT_ID);
            console.log("Subscription bought:", transaction);

            return transaction;
        } catch (err) {
            console.error("Error buying subscription:", err);
            throw err;
        }
    };

export const validateSubscription = async (
    transaction: SubscriptionTransaction
): Promise<Response> => {
    let platform = Capacitor.getPlatform();
    Capacitor.isNativePlatform()

    const isMock = false;

    if (isMock) {
        platform = "ios";
        const mockData = {
            transactionId: "2000000609333091",
            originalTransactionId: "2000000609333091",
            webOrderLineItemId: "2000000062261674",
            bundleId: "com.mediadei.burst",
            productId: "1_month",
            subscriptionGroupIdentifier: "21243045",
            purchaseDate: 1716634028000,
            originalPurchaseDate: 1716634028000,
            expiresDate: 1716634328000,
            quantity: 1,
            type: "Auto-Renewable Subscription",
            inAppOwnershipType: "PURCHASED",
            signedDate: 1716634034116,
            offerType: 1,
            environment: "Sandbox",
            transactionReason: "PURCHASE",
            storefront: "RUS",
            storefrontId: "143469",
            price: 349000,
            currency: "RUB",
            offerDiscountType: "PAY_UP_FRONT",
        };
        return {
            message: "Validation successful",
            data: mockData,
            success: true,
        };
    }

    try {
        console.log("Validation is beginning...");
        console.log("Platform:", platform);
        const validateSubscriptionInfo = await defaultClient.mutate({
            mutation: validateSubscriptionMutation,
            variables: {
                input: {
                    platform,
                    productId: PRODUCT_ID,
                    ...transaction,
                },
            },
        });
        console.log(
            "validateSubscriptionInfo:",
            validateSubscriptionInfo.data.validateSubscription
        );

        const { success, message, data } =
            validateSubscriptionInfo.data.validateSubscription;

        return { success, message, data };
    } catch (error) {
        console.error("Error validating subscription:", error);
        throw new Error("Error validating subscription");
    }
};

export const checkSubscription = async () => {
    try {
        const purchases = await InAppPurchase.restorePurchases();
        console.log("Purchases:", purchases);
        const ownedProduct = purchases.find(
            (purchase) => purchase.productId === PRODUCT_ID && purchase.state === 0
        );
        // state 0 indicates purchased

        console.log("Owned product", ownedProduct);

        if (ownedProduct) {
            return true; // Subscription is active
        } else {
            return false; // No active subscription
        }
    } catch (err) {
        console.error("Error checking subscription:", err);
        throw err;
    }
};
export const processSubscription = async ({ userId }): Promise<Response> => {
    try {
        console.log("Start WooCommerce sync...");
        const processSubscriptionInfo = await defaultClient.mutate({
            mutation: processSubscriptionMutation,
            variables: {
                input: {
                    userId: String(userId),
                },
            },
        });
        console.log("WooCommerce response...", processSubscriptionInfo);
        const { message, success, data } =
            processSubscriptionInfo.data.processSubscription;

        return { message, success, data };
    } catch (error) {
        console.error("Error processing subscription:", error);
        throw new Error("Error processing subscription");
    }
};
