import { createApp, provide, h } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue, iosTransitionAnimation } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { fromPromise } from "@apollo/client/core";

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink
} from "@apollo/client";

import { onError } from "@apollo/client/link/error";

import { DefaultApolloClient } from "@vue/apollo-composable";

import { setContext } from "@apollo/client/link/context";

import { store } from "./store";

import { refreshToken } from "./services/api";

import { App as AppCap } from "@capacitor/app";

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRQ
});
export const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch ((err as any).errorType) {
        case "AuthorizerFailureException": {
          return fromPromise(
            refreshToken((store as any).state.auth.refreshToken).catch((_) => {
              console.log("refreshError", _);
              router.push({ name: "auth" });
            })
          )
            .filter((value) => Boolean(value))
            .flatMap((_) => {
              const oldHeaders = operation.getContext().headers;

              // set new tokens to store
              store.commit("auth/setTokens", (_ as any).data);

              // modify the operation context with a new token
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  Authorization: `Bearer ${(_ as any).data.token}`
                }
              });

              // retry the request, returning the new observable
              return forward(operation);
            });
        }
      }
    }
  }
  return undefined;
});

const authLink = setContext((operation, { headers }) => {
  const token = (store as any).state.auth.token;

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      "x-api-key": process.env.VUE_APP_X_API_KEY
    }
  };
});

export const defaultClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false
  })
});

const app = createApp({
  setup() {
    provide(DefaultApolloClient, defaultClient);
  },
  render() {
    return h(App);
  }
})
  .use(IonicVue, {
    animated: true,
    navAnimation: iosTransitionAnimation
  })
  .use(router)
  .use(store);

router
  .isReady()
  .then(() => {
    app.mount("#app");
  })
  .then(() => {
    AppCap.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        AppCap.exitApp();
      } else {
        router.back();
      }
    });
    AppCap.addListener("appUrlOpen", function (event) {
      // Example url: https:/the.burst.co/tabs/tabs2
      // slug = /tabs/tabs2
      const slug = event.url.split(".co").pop();

      // We only push to the route if there is a slug present
      if (slug) {
        router.push({
          path: slug
        });
      }
    });
  });
