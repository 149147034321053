<template>
  <div class="menu-info">
    <div class="menu-item" v-if="!store.state.auth.isFree">
      <ion-icon :icon="personOutline" />
      <div class="menu-info-block">
        <span class="title">Username</span>
        <div class="info">
          <span>{{ username }}</span>
        </div>
      </div>
    </div>
    <div class="menu-item" v-if="!store.state.auth.isFree">
      <ion-icon :icon="mailOutline" />
      <div class="menu-info-block">
        <span class="title">Email</span>
        <div class="info">
          <span>{{ email }}</span>
        </div>
      </div>
    </div>
    <div class="menu-item" v-if="!store.state.auth.isFree">
      <ion-icon :icon="playCircleOutline" />
      <div class="menu-info-block">
        <span class="title">Subscription</span>
        <div class="info">
          <span>{{ subscription }}</span>
        </div>
      </div>
    </div>
    <router-link class="menu-item-link" to="/watch-history">
      <ion-icon :icon="timeOutline" />
      <span>Watch history</span>
    </router-link>
    <div class="logout-item" v-if="!isWeb && !isFree" @click="restorePurchase()">
      <ion-icon :icon="logOutOutline" />
      <span>Restore purchases</span>
    </div>
    <div class="logout-item" @click="logout()">
      <ion-icon :icon="logOutOutline" />
      <span>Log out</span>
    </div>
    <div class="logout-item delete" v-if="!isWeb && !isFree" @click="logout(true)">
      <ion-icon :icon="logOutOutline" />
      <span>Log out and delete account</span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, ref } from "vue";
import { useStore } from "vuex";
import {
  IonIcon,
  toastController,
} from "@ionic/vue";

import { upper } from "@/helpers/upper";

import { defaultClient } from "@/main";
import getFlags from '@/graphql/getFlags.query.graphql';

import { InAppPurchase as purchase } from '@ionic-native/in-app-purchase';

import {
  mailOutline,
  playCircleOutline,
  timeOutline,
  settingsOutline,
  paperPlaneOutline,
  logOutOutline,
  personOutline
} from "ionicons/icons";
import { isWebPlatform } from "@/helpers/orientation";
import { logEvent } from "@/services/analytics";

export default defineComponent({
  name: "Menu",
  components: {
    IonIcon,
  },
  setup() {
    const ionRouter = inject("navManager") as any;
    const store = useStore();
    const email = store?.state?.auth?.email;
    const username = store?.state?.auth?.username;
    const subscription = upper(store?.state?.auth?.subscriptionInfo);
    const isFree = store.state.auth.isFree;
    const IsTestFlightEnabled = ref(store.getters['cache/IsTestFlightEnabled']);

    const logout = async (isDelete = false) => {
      if (isDelete) {
        try {
          const flags = await defaultClient.query({
            query: getFlags,
          });
        } catch (e) {
          console.log('success delete'); // it's joke
        }
      }
      logEvent('LOGOUT');
      ionRouter.navigate({
        routerDirection: "none",
        routerLink: isDelete ? '/auth?delete=1' : '/auth',
      });
      store.commit("auth/logout");
    };

    const isWeb = ref(isWebPlatform());

    const restorePurchase = async () => {
      try {
        const p = await purchase.restorePurchases();
        console.log(p);
      } catch (e) {
        console.error(e);
        const toast = await toastController.create({
          message: "Something went wrong",
          duration: 2000,
          color: "danger"
        });
        return toast.present();
      }
    }

    return {
      store,
      isWeb,
      email,
      username,
      logout,
      restorePurchase,
      mailOutline,
      playCircleOutline,
      subscription,
      timeOutline,
      personOutline,
      settingsOutline,
      paperPlaneOutline,
      logOutOutline,
      IsTestFlightEnabled,
      isFree,
    };
  }
});
</script>

<style scoped>
.menu-info {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.email {
  display: flex;
  justify-content: space-between;
  font-size: 26px;
}

.menu-item ion-icon {
  font-size: 24px;
  color: #9595ad;
}

.menu-item .title {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  color: #fff;
}

.menu-item .info {
  margin-top: 8.5px;
  display: flex;
  justify-content: space-between;
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  line-height: 20px;
}

.menu-item .info span {
  color: #fff;
}

.menu-item .menu-info-block {
  width: 100%;
  margin-left: 10px;
}

.menu-item .info a {
  display: block;
  color: #658aeb;
  text-decoration: none;
}

.menu-item {
  padding: 23px 15px;
  display: flex;
  color: #fff;
}

.menu-item-link {
  padding: 20px 15px;
  font-weight: 800;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.menu-item-link ion-icon {
  font-size: 23px;
  color: #9595ad;
}

.menu-item-link span {
  color: #fff;
  margin-left: 10px;
}

.logout-item {
  margin-left: 15px;
  margin-top: 45px;
  display: flex;
  align-items: center;
  color: #658aeb;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.delete {
  color: red;
}

.logout-item.delete ion-icon {
  color: red;
}

.logout-item ion-icon {
  font-size: 24px;
  color: #658aeb;
  margin-right: 10px;
}
</style>
