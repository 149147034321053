import { useStore } from "vuex";
import { isWebPlatform } from "./orientation";

export default {
  ionViewDidEnter() {
    if (!useStore()?.state?.cache?.isNavigationShown || !isWebPlatform()) {
      return;
    }
    const $ = (window as any).$;
    /* eslint-disable */
    $.caph.focus.activate(function(nearestFocusableFinderProvider, controllerProvider) {
      console.log('on activate');
      controllerProvider.onFocused(function(event, originalEvent) {
        console.log('on focused');
        $(event.currentTarget).css({
          'box-shadow': '0px 0px 5px 5px #fff'
        });
      });
   
      controllerProvider.onBlurred(function(event, originalEvent) {
          $(event.currentTarget).css({
            'box-shadow': 'none'
        });
      });

       controllerProvider.onSelected(function(event, originalEvent) {
          $(event.currentTarget).toggleClass("selected");
        event.currentTarget.dispatchEvent(new Event('caph'));
        console.log('on selected', event.currentTarget, originalEvent);
      });
    });
  },
};