<template>
  <desktop-header :routeName="routeName"/>
  <transition name="slide-right">
    <div class="auth-modal" v-if="isPopupSubscribeOpen">
      <auth-form :isOnSubscribe="true" :isShowLogin="false" @close="onPopupShown(false)" />
    </div>
  </transition>
  <video id="video" controls playsinline/>
  <ion-app id="ion-app" :routeName="routeName">
    <ion-router-outlet :key="routerKey" />
  </ion-app>
</template>
<script lang="ts">
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { App } from '@capacitor/app';

import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from "vue";
import { isWebPlatform, lock } from "./helpers/orientation";

import AuthForm from "@/components/shared/AuthForm.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { throttle } from "@/helpers/throttle";

import { defaultClient } from "@/main";

import updateTimestamp from "./graphql/updateTimestamp.mutation.graphql";
import createTimestamp from "./graphql/createTimestamp.mutation.graphql";

import DesktopHeader from "./components/shared/DesktopHeader.vue";

import eventBus from "@/helpers/event-bus";

import { SECONDS_TRIAL } from "@/helpers/trial";

import { PushNotifications } from '@capacitor/push-notifications';

import { setInitialInfo, logEvent, enable, setSessionTimeoutDuration } from '@/services/analytics';

import { initializeRemoteConfig } from '@/services/remote-config';
import {  hardUpdateAlert } from '@/helpers/hardUpdateAlert';

import { Smartlook } from '@awesome-cordova-plugins/smartlook';

import { AppMetrica } from 'capacitor-appmetrica-plugin';

import { isIosPlatform, isAndroidPlatform } from "@/helpers/orientation";

import { Badge } from "@ionic-native/badge";


if (isIosPlatform()) {
  AppMetrica.activate({
    apiKey: "eec4e586-8d29-4137-9f99-eb029f78c448",
    logs: true,
    sessionTimeout: 1500,
  }).then(() => {
    // Успешная активация
  }).catch(() => {
    // Что-то пошло не так
  });
} else {
  if (isAndroidPlatform()) {
    AppMetrica.activate({
    apiKey: "792b6d51-f9a9-4433-a9f4-ef0ee87037a7",
    logs: true,
    sessionTimeout: 1500,
  }).then(() => {
    // Успешная активация
  }).catch(() => {
    // Что-то пошло не так
  });
  }
}


const addListeners = async () => {
  await PushNotifications.addListener('registration', token => {
    console.info('Registration token: ', token.value);
  });
  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error);
  });
  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.info('Push notification received: ', notification);
  });
  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
  });
};


let timeoutId: number;

App.addListener('appStateChange', async (state) => {
  if (state.isActive) {
    clearTimeout(timeoutId);
    lock();
    try {
      await hardUpdateAlert();
    } catch (e) {
      console.error('state-change-hua', e);
    }
  } else {
    timeoutId = setTimeout(() => {
      try {
        if (isAndroidPlatform()) {
          App.exitApp();
          }
      }
      catch(e) {
        console.log(e);
      }
    }, 1000 * 60 * 5); // close app after 5 min
  }
});

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    DesktopHeader,
    AuthForm,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const routeName = computed(() => {
      return router.currentRoute.value.name;
    })

    watch(_ => router.currentRoute.value.name, async () => {
      // await setScreenName(String(route.name || route.path));
      // await logEvent('screen_view', {
      //   screen_name: String(route.name || route.path),
      //   params: route.params,
      //   query: route.query,
      // })
    }, { immediate : true});

    const routerKey = ref(0);
    const isPopupSubscribeOpen = ref(false);

    const onRouteUpdate = () => {
      routerKey.value++;
    };
    const onPopupShown = async (payload) => {
      isPopupSubscribeOpen.value = payload;
    }

    eventBus().emitter.on("route-update", onRouteUpdate);
    eventBus().emitter.on('popup-subscribe-shown', onPopupShown);
    
    Smartlook.setProjectKey({ key: '25021c44709d8a8ddf6e54c89b3b1034d812df29' });
    Smartlook.start();

    onBeforeMount(async () => {
      try {
        await addListeners();
        await Badge.set(0);
      } 
      catch (e){
         console.error(e);
      }
      await lock();
      if (router.currentRoute.value.hash === "#nav") {
        router.replace({ hash: "" });
        store.dispatch("cache/setNavigationShown", true);
      }
      if (!isWebPlatform()){ 
        try {
          await initializeRemoteConfig();
          await hardUpdateAlert();
          // setup analytics
          await enable();
          await setInitialInfo(store);
          await setSessionTimeoutDuration(1500);
        } catch (e) {
          console.error('error-init-firebase', e);
        }
      }
    });

    onMounted(async () => {
      const video = document.getElementById("video") as any;

      video!.addEventListener("loadedmetadata", () => {
        const ts = +video.getAttribute("ts") || 0;
        video!.currentTime = ts;
      });

      const playHandler = () => {
        logEvent('START_VIDEO_PLAYING', {
          guid: video.getAttribute("asset-id"),
          title: video.getAttribute('asset-title'),
          timestamp: (+video!.currentTime).toFixed(0),
        });
        logEvent('video_start', {
          video_url: video.getAttribute("src"),
          video_title: video.getAttribute('asset-title'),
          video_current_time: (+video!.currentTime).toFixed(0),
          video_duration: video.getAttribute('duration'),
          video_percent: video.getAttribute('percent'),
        });
      }

      const pauseHandler = () => {
        logEvent('STOP_VIDEO_PLAYING', {
          guid: video.getAttribute("asset-id"),
          title: video.getAttribute('asset-title'),
          timestamp: (+video!.currentTime).toFixed(0),
        })
      };

      const endHandler = () => {
        logEvent('video_complete', {
          video_url: video.getAttribute("src"),
          video_title: video.getAttribute('asset-title'),
          video_current_time: (+video!.currentTime).toFixed(0),
          video_duration: video.getAttribute('duration'),
          video_percent: video.getAttribute('percent'),
        });
      };

      const watchHandler = async (event) => {
        const currentTimePerSecond = video.currentTime;
        video.setAttribute('percent', (+video!.currentTime/+video!.getAttribute('duration')*100).toFixed(0));

        if (store.state.auth.isFree) {
          const timeWatched = store.state.cache.freeSeconds;
          if (timeWatched >= SECONDS_TRIAL) {
            router.push("/home?endTime=1");
          } else {
            store.dispatch("cache/setFreeSeconds", timeWatched + 3);
          }

          if (timeWatched >= (SECONDS_TRIAL / 2) && store.state.cache.halfFreeTimeout !== 'DONE') {
            store.dispatch('cache/setHalfFreeTimeout', 'ACHIEVED');
          }
        }

        const assetId = video.getAttribute("asset-id");
        const tsId = video.getAttribute("ts-id");
        const titleVideo = video.getAttribute('asset-title');

        if (!assetId || !assetId.length) {
          return;
        }

        if (
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        ) {
          try {
            await defaultClient.mutate({
              mutation: updateTimestamp,
              variables: {
                ts: {
                  id: tsId,
                  assetId: assetId,
                  timestamp: parseInt(currentTimePerSecond),
                  userId: String(store.state.auth.userId)
                }
              }
            });
          } catch (e) {
            console.log(e);
            const response = await defaultClient.mutate({
              mutation: createTimestamp,
              variables: {
                ts: {
                  assetId: assetId,
                  published: 1,
                  timestamp: parseInt(currentTimePerSecond),
                  userId: String(store.state.auth.userId)
                }
              }
            });
            video.setAttribute("ts-id", response.data.createTimestamp.id);
          }
        }

        const eventPayload = {
          guid: assetId,
          timestamp: +Number(video!.currentTime).toFixed(),
          title: titleVideo,
        };
        if (event?.type === 'seeked') {
          await logEvent('SEEK_VIDEO', eventPayload);
        } else {
          await logEvent('WATCH_VIDEO', eventPayload);
          logEvent('video_progress', {
            video_url: video.getAttribute("src"),
            video_title: video.getAttribute('asset-title'),
            video_current_time: (+video!.currentTime).toFixed(0),
            video_duration: video.getAttribute('duration'),
            video_percent: video.getAttribute('percent'),
          });
        }
      };
      video!.addEventListener("timeupdate", throttle(watchHandler, 3000));
      video!.addEventListener("seeked", watchHandler);
      video!.addEventListener("play", playHandler);
      video!.addEventListener("pause", pauseHandler);
      video!.addEventListener("ended", endHandler);
    });

    if (store.state.cache.timer.ts) {
      const interval = setInterval(() => {
        const timer = store.state.cache.timer.ts;
        const isAvailable =
          !timer ||
          timer === "false" ||
          timer === "undefined" ||
          timer - new Date().getTime() > 0;
        if (!isAvailable) {
          clearInterval(interval);
          router.push("/lock");
        }
      }, 10000);
    }

    return {
      store,
      onRouteUpdate,
      routerKey,
      router,
      isPopupSubscribeOpen,
      onPopupShown,
      routeName,
    };
  }
});
</script>
<style>
html,
body {
  -webkit-overflow-scrolling: touch;
}

ion-content {
  background: #0b132b;
}

*:not(input) {
  user-select: none;
  -webkit-user-callout: none;
  -webkit-touch-callout: none;
}

/* hide scroll in all internal elements but not for content. However, it effects on mouse drag even. 
  mouse can NOT work anymore
*/
*::-webkit-scrollbar {
  display: none;
}

.no-scroll {
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;
}

ion-app {
  margin: 0 auto;
}

/* @font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/avenir/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2")
      format("woff2"),
    url("/assets/fonts/avenir/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff")
      format("woff");
} */

*:not(ion-alert) {
  font-family: "Avenir";
}

.transperant-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

.absolute-position {
  position: absolute;
}

*:not(ion-alert) .title {
  font-size: 24px;
  line-height: 33px;
  font-weight: 800;
}

*:not(ion-alert) h2 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 800;
}

*:not(ion-alert) h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
}

*:not(ion-alert) h4 {
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
}

*:not(ion-alert) p {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

*:not(ion-alert) a {
  text-decoration: none !important;
  color: #fff !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
select.form-control {
  background: transparent;
  border: none;
  border-bottom: 2px solid purple;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}


.slide-right-active,
.slide-right-leave-active {
  transition: all .5s ease-in-out;
  transform: translateX(0);
}

.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(100vw);
}

@keyframes slidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.auth-modal {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  background: #171A2B;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  animation: slidein .4s ease-in-out;
}

video#video {
  position: fixed;
  left: 0;
  width: 100%;
  display: none;
  z-index: 100;
  /* background: green; */
}

video#video.show {
  display: block;
}

video#video:not(:fullscreen) {
  height: 63vw;
  background: black;
  top: calc(var(--ion-safe-area-top, 0)*0.95 + 70px);
}

@media screen and (min-width: 960px) {
  ion-header {
    display: none !important;
  }

  ion-app:not(.with-desktop-header) {
    top: 98px !important;
  }

  ion-app:not(.with-desktop-header)[routename="asset"] {
    top: 0px !important;
  }

  ion-content {
    padding-bottom: 0 !important;
  }

  ion-content .content-container {
    min-height: calc(100vh - 255px);
  }

  ion-button {
    border-radius: 25px;
    transition: all 0.2s ease-in-out;
  }

  video#video:not(:fullscreen) {
    height: 37vw;
    background: black;
    top: 118px;
  }

  video#video {
    left: auto;
    right: 60px;
    width: calc(50vw - 80px);
    top: 118px;
    border-radius: 29px;
  }

  .auth-modal {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    background: #171A2B;
    height: 100vh;
    width: 70vw;
    z-index: 1000;
    animation: slidein .4s ease-in-out;
  }
}
</style>
