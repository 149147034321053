import { registerPlugin } from "@capacitor/core";


export interface AppAnalyticsPlugin {
    sendAnalytics(options: {key: string, value: {[key: string]: any}}): Promise<void>;
    getRemoteConfig(): Promise<{key: String, value: any}>;
}

const analyticsManager = registerPlugin<AppAnalyticsPlugin>('AnalyticsPlugin');


export default analyticsManager;
