import { alertController } from "@ionic/vue";

import remoteConfigManager from '@/customPlugins/AppRemoteConfig';

import { isIosPlatform, isAndroidPlatform, isWebPlatform } from "@/helpers/orientation";

const minimum_version_hard = 'minimum_version_hard';
const is_need_hard_update_by_minimum_version = 'is_need_hard_update_by_minimum_version';

import { compareVersions } from 'compare-versions';

import { nativeLinkHandler } from '@/helpers/nativeLinkHandler';

const PACKAGE_VERSION = '2.12.1';

export const hardUpdateAlert = async () => {
  if (isWebPlatform()) {
    return;
  }
  let minimumVersionHard = '';
  let isNeed = false as any;
  if (isIosPlatform() || isAndroidPlatform()) {
    const config = await remoteConfigManager.getRemoteConfig() as any;
    minimumVersionHard = config.config[minimum_version_hard];
    isNeed = config.config[is_need_hard_update_by_minimum_version] === 'true';
  }

  console.log('CHECK_HARDUPDATE', minimumVersionHard, isNeed);
  if (isNeed) {
    if (compareVersions(minimumVersionHard, PACKAGE_VERSION)) {
      const alert = await alertController.create({
        header: 'New app version',
        message: 'This app has a new version\navailable.\nClick to update.',
        backdropDismiss: false,
        buttons: [{
          text: 'Update',
          handler: () => {
            const link = isIosPlatform() ? 'https://apps.apple.com/tr/app/burst/id1597768813' : 'https://play.google.com/store/apps/details?id=co.theburst.app';
            nativeLinkHandler(link);
            return false;
          }
        }],
      });
      await alert.present();
    }
  }
}