<template>
  <ion-page>
    <ion-tabs id="tab-bar">
      <ion-router-outlet/>
      <ion-tab-bar slot="bottom" id="tab-bar-bottom">
        <ion-tab-button
          v-for="(tab, index) in tabs"
          :key="index"
          :data-selected="tab.tab === currentRoute"
          :selected="tab.tab === currentRoute"
          :tab="tab.tab"
          @click="onClick(tab)"
        >
          <ion-icon :icon="tab.icon" />
          <ion-label>{{ upper(tab.tab) }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import {
  filmOutline,
  compassOutline,
  searchOutline,
  menuOutline
} from "ionicons/icons";
import { upper } from "@/helpers/upper";
import { useRouter } from "vue-router";
import { ref, inject, onMounted, watch } from "vue";
import SweetScroll from "sweet-scroll";

export default {
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup: () => {
    const router = useRouter();

    const ionRouter: any = inject("navManager");

    const tabs = [
      {
        tab: "home",
        icon: filmOutline,
        prev: null
      },
      {
        tab: "explore",
        icon: compassOutline,
        prev: null
      },
      {
        tab: "search",
        icon: searchOutline,
        prev: null
      },
      {
        tab: "menu",
        icon: menuOutline,
        prev: null
      }
    ];

    const currentRoute = ref(
      tabs.find(_ => _.tab === router.currentRoute.value.name)?.tab || "home"
    );

    watch(() => router.currentRoute.value, () => {
      if (router.currentRoute.value.name === 'auth' || router.currentRoute.value.name === 'login'  || router.currentRoute.value.name === 'register') {
        currentRoute.value = "home"
      }
    }, { immediate: true });

    const onClick = (tab: any) => {
      if (router.currentRoute.value.name === tab.tab) {
        // click on root tab
        new SweetScroll(
          {
            duration: 1000,
            easing: "easeOutExpo"
          },
          `ion-content#${tab.tab}-content`
        ).toTop(0);
      }
       else {
        if (currentRoute.value === tab.tab) {
          // click on nested tab
          ionRouter.navigate({
            routerDirection: "back",
            routerLink: "/" + tab.tab
          });
        } else {
          // click another tab
          (tabs.find(_ => _.tab === currentRoute.value) as any).prev =
            router.currentRoute.value.path;
          const newLink =
            (tabs.find(_ => _.tab === tab.tab) as any)?.prev ||
            `/${tab.tab}`;
          ionRouter.navigate({
            routerDirection: "none",
            routerLink: newLink
          });
        }
      }
      currentRoute.value = tab.tab;
    };

    return {
      router,
      onClick,
      tabs,
      upper,
      currentRoute,
      onMounted
    };
  }
};
</script>
<style scoped>
ion-tab-button {
  --color-selected: #ee9a1b;
}
ion-tab-bar,
ion-tab-button,
ion-tabs {
  background: #091024 !important;
}

ion-tabs {
  padding-bottom: 12px;
}

ion-tab-bar.hide {
  display: none;
}

ion-tab-bar,
ion-tabs {
  box-shadow: -1px -3px 8px 3px rgba(3, 17, 27, 0.4);
}

ion-tab-button {
  margin-top: 5px;
}

ion-tab-button[data-selected="true"] {
  color: #ee9a1b;
}

ion-label {
  margin-top: 3px;
}
ion-icon {
  font-size: 23px;
  line-height: 14px;
  top: 0px;
}

@media screen and (min-width: 960px) {
  ion-tab-bar {
    display: none;
  }
  ion-tabs {
    padding: 0;
    margin: 0;
  }
}
</style>
