<template>
<div @click="onClick" v-if="isFree && timeWatched !== null && SECONDS_TRIAL>= timeWatched" class="free-timer">
  <div class="free-timer-counter">
    {{time}}
  </div>
  <div class="free-timer-label">Upgrade</div>
</div>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { SECONDS_TRIAL } from '@/helpers/trial';

const stringify = (n) => {
  if (String(n).length === 1) {
    return `0${n}`;
  } else {
    return String(n);
  }
}

export default defineComponent({
  name: "FreeTimer",
  setup(_props, context) {
    const store = useStore();
    const timeWatched = computed(() => store.state.cache.freeSeconds);
    const isFree = computed(() => store.state.auth.isFree);
    const time = computed(() => {
      if (timeWatched.value <= SECONDS_TRIAL) {
        const totalSeconds = SECONDS_TRIAL - timeWatched.value;
        const minutes = stringify(Math.floor(totalSeconds / 60));
        const seconds = stringify(totalSeconds % 60);
        return `${minutes}:${seconds}`;
      } else {
        return null;
      }
    })
    const onClick = () => {
      context.emit('open-modal');
    }

    return {
      isFree,
      time,
      onClick,
      timeWatched,
      SECONDS_TRIAL,
    }
  },
});
</script>

<style scoped>
.free-timer {
  border-radius: 13px;
  overflow: hidden;
  line-height: 14px;
  width: min-content;
  text-align: center;
  cursor: pointer;
}

.free-timer .free-timer-counter {
  background: rgba(12, 20, 45, .6);
  color: #fff;
  width: 100%;
  padding: 4px 9px 2px;
  font-weight: 600;
  font-size: 14px;
  font-family: Avenir, sans-serif;
}
.free-timer-label {
  width: 100%;
  background: rgb(12, 20, 45);
  color: rgb(244, 165, 45);
  padding: 4px 9px;
  font-size: 10px;
  font-weight: 600;
}
</style>