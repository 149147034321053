<template>
<span @click="onClick">
  <slot></slot>
</span>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import { nativeLinkHandler } from '@/helpers/nativeLinkHandler'

export default defineComponent({
  name: "NativeLink",
  props: {
    href: {
      required: true,
    },
  },
  setup(props, context) {
    const onClick = () => {
      const URL = props.href as string;
      nativeLinkHandler(URL);
    }; 
    return {
      onClick,
    }
  }
})
</script>

<style>

</style>