import { InAppBrowser } from '@ionic-native/in-app-browser';

import { SafariViewController } from '@ionic-native/safari-view-controller';
import { isWebPlatform } from "@/helpers/orientation";

export const nativeLinkHandler = (URL) => {
  try {
    if (!isWebPlatform()) {
      SafariViewController.isAvailable().then((isAvailable: boolean) => {
        if (isAvailable) {
          SafariViewController.show({
            url: URL,
            hidden: false,
            animated: true,
            transition: 'curl',
            tintColor: '#0b132b',
          })
            .subscribe((result: any) => {
              if (result.event === 'opened') console.log('Opened');
              else if (result.event === 'loaded') console.log('Loaded');
              else if (result.event === 'closed') console.log('Closed');
            },
              (error: any) => console.error(error)
            );
        } else {
          const browser = InAppBrowser.create(URL, '', {
            closebuttoncaption: 'Close',
            hidenavigationbuttons: 'yes',
            hideurlbar: 'yes',
            usewkwebview: 'yes',
            presentationstyle: 'fullscreen',
            footer: 'yes',
            location: 'no',
            toolbar: 'yes',
          });
        }
      });
    } else {
      location.assign(URL);
    }
  }
  catch(e) {
    location.assign(URL);
  }
};