import axios, { AxiosPromise } from "axios";

const store = JSON.parse(localStorage.getItem("vuex")!);
const token = store?.auth?.token;
const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: token && `Bearer ${token}` || null,
  },
});

export const auth = {
  login: (cred: Record<string, any>) => {
    return api.post(
      process.env.VUE_APP_API_URL + "/user/login",
      JSON.stringify(cred)
    );
  },
  freeLogin: () => {
    return api.post(
      process.env.VUE_APP_API_URL + "/user/self",
    );
  },
  register: (cred: Record<string, any>) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          process.env.VUE_APP_API_URL + "/user/register",
          JSON.stringify(cred)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  signup: (cred: Record<string, any>): AxiosPromise => {
    return new Promise((resolve, reject) => {
      api
        .post(
          process.env.VUE_APP_API_URL + "/user/signup",
          JSON.stringify(cred)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPassword: (cred: Record<string, any>) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          process.env.VUE_APP_API_URL + "/user/resetpassword",
          JSON.stringify(cred)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export const subscriptions = {
  processUserSubscription: async (record: Record<string, any>) => {
    try {
      const { data } = await axios.post(
        "https://eck6e5dp6j.execute-api.us-east-1.amazonaws.com/dev/subscriptions/processUserSubscription",
        JSON.stringify(record)
      );

      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  consumeUserSubscription: async (record: Record<string, any>) => {
    try {
      const { data } = await axios.post(
        "https://eck6e5dp6j.execute-api.us-east-1.amazonaws.com/dev/subscriptions/consumeUserSubscription",
        JSON.stringify(record)
      );

      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}

const subscriptionUrl =
  `${process.env.VUE_APP_API_URL}/actions/checkCustomerSubscription/`;

export const subsciption = (partnerId: number): Promise<{ data: any[] }> =>
  api.get(`${subscriptionUrl}${partnerId}`);

export const refreshToken = (refreshToken: string) => axios.post(`${process.env.VUE_APP_API_URL}/user/refresh-token`, {
  refreshToken,
});
