/* eslint-disable */
//@ts-nocheck
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '@/components/shared/Tabs.vue'
import { store } from '../store';
import { daysDiff, DAYS_TRIAL } from '@/helpers/trial';

function operate (guards, from, to, lastNext, i) {
  let guard = guards[i]
  if (guards.length === i + 1) {
    guard(from, to, lastNext)
  } else {
    guard(from, to, function (nextArg) {
      switch (typeof (nextArg)) {
        case 'undefined':
          operate(guards, from, to, lastNext, i + 1)
          break
        case 'object':
          lastNext(nextArg)
          break
        case 'boolean':
          lastNext(nextArg)
          break
        case 'string':
          lastNext(nextArg)
          break
      }
    })
  }
}

function GuardsCheck (listOfGuards) {
  return function (from, to, next) {
    operate(listOfGuards, from, to, next, 0)
  }
}

function AuthGuardReverse(to: any, from: any, next: any) {
  const localStore: any = store;
  const isAuthenticated = localStore?.state?.auth?.token;
  if (isAuthenticated) {
    next('/home'); // allow to enter route
  }
  else {
    next(); // allow to enter route
  }
}

function AuthGuard(to: any, from: any, next: any) {
  const localStore: any = store;
  const isAuthenticated = localStore?.state?.auth?.token;
  if (isAuthenticated) {
    const timeEnter = new Date(+store.state.cache.freeDaysEnter);
    if (daysDiff(Date.now(), timeEnter) >= DAYS_TRIAL) {
      store.dispatch('cache/setFreeDays', Date.now());
      store.dispatch('cache/setFreeSeconds', 0);
    }
    next(); // allow to enter route
  }
  else {
    next('/auth'); // go to '/login';
  }
}

function TimerGuard(to: any, from: any, next: any) {
  const localStore: any = store;
  const timer = localStore.state.cache.timer.ts;
  const isAvailable = !timer || timer === 'false' || timer === 'undefined' || ((timer - new Date().getTime()) > 0);
  if (isAvailable) {
    next(); // allow to enter route
  }
  else {
    next('/lock');
  }
}

const AuthAndTimerGuard = GuardsCheck([AuthGuard, TimerGuard]);

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
    beforeEnter: AuthAndTimerGuard,
  },
  {
    path: '/',
    beforeEnter: AuthAndTimerGuard,
    meta: {
      requiresAuth: true
    },
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/home'
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'explore',
        name: 'explore',
        component: () => import('@/views/Explore.vue')
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('@/views/Search.vue'),
        props: (router) => ({ text: router.query.search }),
      },
      {
        path: 'menu',
        name: 'menu',
        component: () => import('@/views/Menu.vue')
      },
      {
        path: 'category/:category',
        component: () => import('@/views/Category.vue'),
        props: true,
        name: 'category',
      },
      {
        path: 'genre/:genre',
        component: () => import('@/views/Category.vue'),
        props: true,
        name: 'genre',
      },
      {
        path: '/reset-password',
        component: () => import('@/views/ResetPassword.vue')
      },
      // {
      //   path: '/settings',
      //   component: () => import('@/views/Settings.vue')
      // },
      // {
      //   path: '/edit-email',
      //   component: () => import('@/views/EditEmail.vue')
      // },
      {
        path: '/watch-history',
        component: () => import('@/views/WatchHistory.vue'),
        name: 'watch-history',
      },
      {
        path: 'asset/:id',
        component: () => import('@/views/Asset.vue'),
        name: 'asset',
        props: true,
      },
    ]
  },
  {
    path: '/watch-now',
    component: () => import('@/views/WatchNowVideo.vue'),
    props: true,
    beforeEnter: AuthAndTimerGuard,
    name: 'watch-now',
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/Gateway.vue'),
    beforeEnter: AuthGuardReverse,
  },
  {
    path: '/lock',
    name: 'lock',
    component: () => import('@/views/Lock.vue'),
  },
  {
    path: '/email',
    component: () => import('@/views/registration/Email.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return { left: 0, top: 0 }
  }
})

export default router
