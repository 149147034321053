import { getPlatforms } from "@ionic/vue";

export const isWebPlatform = () => !getPlatforms().find(_ => _ === 'ios' || _ === 'android')

export const isIosPlatform = () => getPlatforms().find(_ => _ === 'ios');
export const isAndroidPlatform = () => getPlatforms().find(_ => _ === 'android');



export const lock = async () => {
  if (isWebPlatform()) {
    return;
  }

  try {
    if (!isWebPlatform() && !getPlatforms().find(_ => _ === 'tablet' || _ === 'ipad')) {
      await screen?.orientation?.lock?.("portrait");
    } else {
      await screen?.orientation?.lock?.("landscape");
    }
  } catch (e) {
    console.error(e);
  }
}

export const unlock = async () => {
  if (isWebPlatform()) {
    return;
  }

  try {
    await screen?.orientation?.unlock();
  } catch (e) {
    console.error(e);
  }
}
