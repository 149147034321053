const KEY_TEST_FLIGHT = "ABNewHomePage";

const state = {
  searchHistory: [],
  isNavigationShown: false,
  timer: {
    ts: false,
    timeout: 0,
  },
  halfFreeTimeout: 'NOT_ACHIEVED', // 'DONE' || 'ACHIEVED'
  freeSeconds: null, // @TODO change for null (for test 3550)
  freeDaysEnter: null,
  featureFlags: [
    {
      key: KEY_TEST_FLIGHT,
      value: true
    }
  ],
  subscribeInfo: {
    price: '$4.95',
  },
  remoteConfig: null,
};

const actions = {
  setRemoteConfig(store, payload) {
    store.commit('setRemoteConfig', payload);
  },
  setHalfFreeTimeout(store, payload) {
    store.commit('setHalfFreeTimeout', payload);
  },
  addSearchHistory(store, newValue) {
    if (newValue === "") {
      return;
    }
    const searchHistory = [newValue, ...store.state.searchHistory]
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter((t) => t?.length)
      .splice(0, 5);
    store.commit("setSearchHistory", searchHistory);
  },
  setTimestampTimer({ commit }, { timestamp, timeout }) {
    commit("setTimestampTimer", { timestamp, timeout });
  },
  setNavigationShown({ commit }, payload) {
    commit("setNavigationShown", payload);
  },
  setFreeSeconds({ commit }, payload) {
    commit("setFreeSeconds", payload);
  },
  setFreeDays({ commit }, payload) {
    commit("setFreeDays", payload);
  },
  setFeatureFlags({ commit }, payload) {
    commit("setFeatureFlags", payload);
  },
  setSubscribePrice({ commit }, payload) {
    commit('setSubscribePrice', payload);
  }
};

const mutations = {
  setRemoteConfig(state, payload) {
    state.remoteConfig = payload;
  },
  setHalfFreeTimeout(state, payload) {
    state.halfFreeTimeout = payload;
  },
  setSearchHistory(state, searchHistory) {
    state.searchHistory = searchHistory;
  },
  setFeatureFlags(state, payload) {
    state.featureFlags = payload;
  },
  setSubscribePrice(state, payload) {
    state.subscribeInfo.price = payload;
  },
  setTimestampTimer(state, { timestamp, timeout }) {
    state.timer.ts = timestamp;
    state.timer.timeout = timeout;
  },
  setNavigationShown(state, payload) {
    state.isNavigationShown = payload;
  },
  setFreeSeconds(state, payload) {
    state.freeSeconds = payload;
  },
  setFreeDays(state, payload) {
    state.freeDaysEnter = payload;
  }
};

const getters = {
  IsTestFlightEnabled(state): boolean {
    return false;
    // return toRaw(state.featureFlags).find(
    //   (x) => x.key === KEY_TEST_FLIGHT
    // ).value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
