import { upper } from "@/helpers/upper";
import { setUserProperties } from "@/services/analytics";

// TODO Fix types later
// @ts-nocheck
const state = {
    isLoading: false,
    token: '',
    refreshToken: '',
    email: '',
    userId: '',
    username: null,
    partnerId: undefined,
    continueWatchingAssets: [],
    subscriptionInfo: '',
    subscriptionId: null,
    isFree: null,
    Iid: null,
    freeUser: {
        userId: null,
        token: null,
    },
}

const actions = {
    setToken({ commit }, token) {
        commit('setToken', token);
    },
    setTokens({ commit }, tokens) {
        commit('setTokens', tokens);
    },
    removeToken({ commit }) {
        commit('setToken', '');
    },
    setEmail({ commit }, email) {
        commit('setEmail', email);
    },
    setUsername({ commit }, username) {
        commit('setUsername', username);
    },
    setPartnerId({ commit }, partnerId) {
        commit('setPartnerId', partnerId);
    },
    setClientInfo({ commit }, payload) {
        commit('setClientInfo', payload);
    },
    setClientInfoFree({ commit }, payload) {
        commit('setClientInfoFree', payload);
    },
    removeEmail({ commit }) {
        commit('setEmail', '');
    },
    setUserId({ commit }, userId) {
        commit('setUserId', userId);
    },
    removeUserId({ commit }) {
        commit('setUserId', '');
    },
    setContinueWatchingAssets({ commit }, continueWatchingAssets) {
        commit('setContinueWatchingAssets', continueWatchingAssets);
    },
    logout({ commit }) {
        commit('logout');
    },
    setIid({ commit }) {
        commit('setIid');
    },
}
const mutations = {
    setToken(state, token) {
        state.token = token;
    },
    setTokens(state, tokens) {
        state.token = tokens.token;
        state.refreshToken = tokens.refreshToken;
    },
    setPartnerId(state, partnerId) {
        state.partnerId = partnerId;
    },
    setEmail(state, email) {
        state.email = email
    },
    async setClientInfo(state, payload) {
        state.username = payload.username;
        state.partnerId = payload.partnerId;
        state.token = payload.token;
        state.email = payload.email;
        state.userId = payload.userId;
        state.subscriptionId = payload.subscription?.id;
        state.subscriptionInfo = `${upper(payload.subscription?.status)} by ${new Date(payload.subscription?.date_created_gmt).toLocaleDateString('en-US')}`;
        state.refreshToken = payload.refreshToken;
        state.isFree = false;
        await setUserProperties({
            accountId: payload.userId,
            username: payload.username,
            email: payload.email,
            subscriptionId: payload.subscription?.id,
            subscriptionInfo: state.subscriptionInfo,
            hasPaidAccount: true,
        })
    },
    async setClientInfoFree(state, payload) {
        state.isFree = true;
        // double check
        if (state.freeUser.token && state.freeUser.userId) {
            state.token = state.freeUser.token;
            state.userId = state.freeUser.userId;
        } else {
            state.token = payload.token;
            state.userId = payload.userId;
            // save local free user forever
            state.freeUser.token = payload.token;
            state.freeUser.userId = payload.userId;
        }
        await setUserProperties({
            freeAccountId: state.userId,
            hasFreeAccount: true,
        })
    },
    async setUserId(state, userId) {
        state.userId = userId
        // await setUserId(userId);
    },
    setContinueWatchingAssets(state, continueWatchingAssets) {
        state.continueWatchingAssets = continueWatchingAssets
    },
    setUsername(state, username) {
        state.username = username;
    },
    setIid(state, payload) {
        state.iid = payload;
    },
    async logout(state) {
        state.token = '';
        state.username = null;
        state.partnerId = undefined;
        state.userId = '';
        state.email = '';
        state.isFree = null;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
