import { FirebaseRemoteConfig } from '@capacitor-firebase/remote-config';
import remoteConfigManager from '@/customPlugins/AppRemoteConfig';
import { isIosPlatform, isAndroidPlatform } from '@/helpers/orientation';

import { store } from "@/store";

const storeT = store as any;

// const LS_KEY_REMOTE_CONFIG_IOS = 'remote_config_ios';

const getIosRemoteConfig = async (key: string, type: string) => {
  // console.log('get-ios-remote-config', key, type);
  // // const configString = localStorage.getItem(LS_KEY_REMOTE_CONFIG_IOS);
  // // const config
  // if (storeT.state.cache.remoteConfig === null) {
  //   await initializeIosRemoteConfig();
  // }

  // const configObject = storeT.state.cache.remoteConfig;
  // // const configStringNotEmpty = localStorage.getItem(LS_KEY_REMOTE_CONFIG_IOS) as string;
  // // console.log('config-string', configStringNotEmpty)
  // // const configObject = JSON.parse(configStringNotEmpty);
  // console.log('config-object', configObject);
  // const value = configObject.config[key];
  // console.log('config-value', value);
  // console.log(value);
  // if (type === 'number') {
  //   return +value;
  // }
  // if (type === 'boolean') {
  //   return value === 'true';
  // }
  // return value;
  return null;
}

const initializeIosRemoteConfig = async () => {
  try {
    const config = await remoteConfigManager.getRemoteConfig();
    storeT.dispatch('cache/setRemoteConfig', config);
    // localStorage.setItem(LS_KEY_REMOTE_CONFIG_IOS, JSON.stringify(config))
    console.log('Recieved remote config!', config);
  } catch (e) {
    console.error('Error remote config', e);
  }
}

export const initializeRemoteConfig = async () => {
  if (isIosPlatform()) { // only ios
    await initializeIosRemoteConfig();
  } else {
    try {
      await FirebaseRemoteConfig.fetchAndActivate();
      await FirebaseRemoteConfig.fetchConfig({
        minimumFetchIntervalInSeconds: 240,
      });
    } catch (e) {
      console.error('not-initialize-remote-config', e);
    }
  }
}

export const getString = async (key: string) => {
  try {
    if (isIosPlatform()) {
      const t = await getIosRemoteConfig(key, 'string');
      console.log('get-string', t)
      return t;
    }
    return (await FirebaseRemoteConfig.getString({ key })).value;
  } catch (e) {
    console.error(e);
  } finally {
    return '';
  }
}

export const getNumber = async (key: string) => {
  try {
    if (isIosPlatform()) {
      return getIosRemoteConfig(key, 'number');
    }
    return (await FirebaseRemoteConfig.getNumber({ key })).value;
  } catch (e) {
    console.error(e);
  } finally {
    return NaN;
  }
}

export const getBoolean = async (key: string) => {
  try {
    if (isIosPlatform()) {
      return getIosRemoteConfig(key, 'boolean');
    }
    return (await FirebaseRemoteConfig.getBoolean({ key })).value;
  } catch (e) {
    console.error(e);
  } finally {
    return false;
  }
}
