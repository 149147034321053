import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { AppMetrica } from 'capacitor-appmetrica-plugin';

import { TEvents } from './types';
import { uuid } from 'uuidv4';

export const setInitialInfo = async (store: any) => {
  if (!store.state.auth.iid) {
    const newIid = uuid();
    store.dispatch('auth/setIid', newIid);
  }

  await setUserId(store.state.auth.iid);
}

export const setUserId = async (userId) => {
  console.log('set-user-id', userId);
  try {
    await FirebaseAnalytics.setUserId({ userId });
    await AppMetrica.setUserProfileId({ id: userId })
  } catch (e) {
    console.error('set-user-id-error', e);
  }
};

export const setUserProperties = async (payload: { [key: string]: any }) => {
  console.log('set-user-properties', Object.entries(payload).map(([name, value]) =>({ name, value: String(value) })));
  try {
    await Promise.all(Object.entries(payload).map(([key, value]) => FirebaseAnalytics.setUserProperty({ key, value: String(value) })));
    await AppMetrica.reportUserProfile(payload);
  } catch (e) {
    console.error('set-user-properties-error', e);
  }
};

export const setScreenName = async (screenName: string, screenClassOverride?: string) => {
  console.log(screenName, screenClassOverride);
  try {
    await FirebaseAnalytics.setCurrentScreen({
      screenName,
      screenClassOverride: screenClassOverride,
    });
    await AppMetrica.reportEvent({
      name: 'PAGE_VIEW',
      params: {
        screenName,
        screenClassOverride,
      }
    });
  } catch (e) {
    console.error('set-screen-name-error', e);
  } 
};

export async function logEvent<T extends keyof TEvents>(name: T, params: TEvents[T] | Object = {}) {
  console.log(name, params);
  try {
    await FirebaseAnalytics.logEvent({
      name,
      params,
    });
    await AppMetrica.reportEvent({
      name,
      params,
    });
  } catch (e) {
    console.error('log-event-error', e);
  }
}

export const setSessionTimeoutDuration = async (duration = 1800) => {
  try {
    await FirebaseAnalytics.setSessionTimeoutDuration({
      duration,
    });
  } catch (e) {
    console.error('e');
  }
};

export const enable = async () => {
  try {
  await FirebaseAnalytics.setEnabled({ enabled: true});
  } catch (e) {
    console.error(e);
  }
};
