import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timer" }
const _hoisted_2 = {
  key: 1,
  class: "time-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_progress = _resolveComponent("circle-progress")!
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.percentage >= 0 && _ctx.percentage <= 100)
      ? (_openBlock(), _createBlock(_component_circle_progress, {
          key: 0,
          size: 40,
          percentage: _ctx.percentage,
          strokeWidth: 2,
          ringColor: "#fff",
          progressColor: "#5863F8"
        }, null, 8, ["percentage"]))
      : _createCommentVNode("", true),
    (_ctx.percentage >= 0 && _ctx.percentage <= 100)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.timeLeft), 1))
      : (_openBlock(), _createBlock(_component_ion_icon, {
          key: 2,
          icon: _ctx.stopwatchOutline
        }, null, 8, ["icon"]))
  ]))
}