<template>
  <div class="auth-form">
    <ion-icon :icon="chevronBackOutline" class="close-modal" @click="close" />
    <div class="login form" v-if="isOnLogin" @keyup.enter="loginUser">
      <div class="title-form">Login to your account</div>
      <ion-item ref="emailLogin">
        <ion-label focusable position="floating"> Email </ion-label>
        <ion-input type="email" v-model="cred.email" placeholder="Enter Email" class="input-login" autocomplete="email"
          id="input-email-login" enterkeyhint="next" autofocus @keyup.enter.stop="goToPass('login')"
          @ionInput="validateEmailInput($event, 'email-login')" @ionBlur="markTouched('email-login')"></ion-input>
        <ion-note slot="error">
          <div class="error-wrapper">
            <ion-icon :icon="alertCircleOutline" />
            <span> Please enter a valid email </span>
          </div>
        </ion-note>
      </ion-item>
      <ion-item focusable class="eye-wrapper">
        <ion-label position="floating">Password</ion-label>
        <ion-input :type="isPasswordShow ? 'text' : 'password'" autocomplete="current-password" v-model="cred.password"
          placeholder="Enter password" class="input-login" id="input-password-login" :clearOnEdit="false"
          enterkeyhint="done" @ionFocus="onFocus" @ionBlur="offFocus"></ion-input>
        <!-- <ion-icon v-show="true" :icon="isPasswordShow ? eyeOffOutline : eyeOutline" class="eye"
        @click.stop="togglePasswordShow" /> -->
      </ion-item>
      <span class="login-span forgot"><native-link href="https://theburst.co/my-account/lost-password/">Forgot
          password?</native-link></span>
      <ion-button shape="round" expand="block" @click="loginUser" class="button-form"
        :disabled="isLoading || isDisabled">
        <span v-if="!isLoading">Login</span>
        <ion-spinner v-else />
      </ion-button>
      <span class="login-span" @click="subscribe">Don’t have an account? <strong>Sign Up</strong></span>
    </div>
    <div class="subscribe form" v-if="isOnSubscribe">
      <div class="title-form">Premium Subscribe</div>
      <ion-item ref="emailSubscribe">
        <ion-label position="floating"> Email </ion-label>
        <ion-input type="email" v-model="cred.email" class="input-login" autocomplete="email" id="input-email-subscribe"
          enterkeyhint="next" autofocus @keyup.enter.stop="goToPass('subscribe')"
          @ionInput="validateEmailInput($event, 'email-subscribe')"
          @ionBlur="markTouched('email-subscribe')"></ion-input>
        <ion-note slot="error">
          <div class="error-wrapper">
            <ion-icon :icon="alertCircleOutline" />
            <span> Please enter a valid email </span>
          </div>
        </ion-note>
      </ion-item>
      <ion-item ref="passSubscribe">
        <ion-label position="floating"> Password </ion-label>
        <ion-input type="password" v-model="cred.password" class="input-login" autocomplete="new-password"
          id="input-password-subscribe" enterkeyhint="next" @keyup.enter.stop="goToPass('confirm-subscribe')"
          @ionInput="validatePassInput($event, 'confirm-pass-subscribe')"
          @ionBlur="markTouched('confirm-pass-subscribe')"></ion-input>
      </ion-item>
      <ion-item ref="passConfirmSubscribe">
        <ion-label position="floating"> Confirm Password </ion-label>
        <ion-input type="password" v-model="cred.passwordConfirm" class="input-login" autocomplete="new-password"
          id="input-password-confirm-subscribe" enterkeyhint="next" @keyup.enter.stop=""
          @ionInput="validatePassInput($event, 'confirm-pass-subscribe')"
          @ionBlur="markTouched('confirm-pass-subscribe')"></ion-input>
        <ion-note slot="error" v-if="validatePassMessage()">
          <div class="error-wrapper">
            <ion-icon :icon="alertCircleOutline" />
            <span>
              {{ validatePassMessage() }}
            </span>
          </div>
        </ion-note>
      </ion-item>
      <ion-item class="checkbox-item" lines="none">
        <ion-checkbox slot="start" color="primary" :checked="isChecked" @ionChange="onCheckedChange"></ion-checkbox>
        <div class="label">
          By clicking “Subscribe" you agree to
          all&nbsp;terms&nbsp;and&nbsp;conditions
        </div>
      </ion-item>
      <ion-button shape="round" expand="block" @click="signUpAndSubscribe" class="button-form"
        :disabled="isLoading || isDisabled">
        <span v-if="!isLoading">Subscribe for {{ store.state.cache.subscribeInfo.price }}/mo</span>
        <ion-spinner v-else />
      </ion-button>
      <span v-if="isShowLogin" class="login-span" @click="login">Already have an account? <strong>Login</strong></span>
      <span class="terms"><native-link href="https://theburst.co/burst-plus-terms-of-use/"><strong>Terms of
            Service</strong></native-link>
        and
        <native-link href="https://theburst.co/burst-plus-privacy-policy/"><strong>Privacy
            Policy</strong></native-link></span>
    </div>
  </div>
</template>
<script lang="ts">
import {
  IonPage,
  IonContent,
  IonFooter,
  IonToolbar,
  IonButton,
  IonGrid,
  IonInput,
  IonIcon,
  IonItem,
  IonList,
  IonLabel,
  IonCheckbox,
  IonCol,
  IonModal,
  IonText,
  IonRow,
  IonSpinner,
  IonNote,
  IonSlides,
  IonSlide,
  onIonViewWillEnter,
  onIonViewDidEnter,
  toastController,
} from "@ionic/vue";
import { defineComponent, ref, inject, computed, nextTick, watch, ComputedRef, Ref } from "vue";

import eventBus from "@/helpers/event-bus";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import caph from "@/helpers/caph";

import { auth, subscriptions } from "@/services/api";

import { validateSubscription, processSubscription, initializeStore, checkSubscription, storeBuySubscription, isSandboxEnvironment } from '@/services/in-app-purchase'

import {
  eyeOutline,
  eyeOffOutline,
  chevronBackOutline,
  alertCircleOutline,
} from "ionicons/icons";
import NativeLink from "@/components/shared/NativeLink.vue";
import analyticsManager from "../../customPlugins/AppAnalytics";

import { isWebPlatform } from "@/helpers/orientation";
import { logEvent } from "@/services/analytics";

const PRODUCT_ID = "1_month";

export default defineComponent({
  name: "Auth",
  components: {
    IonContent,
    IonPage,
    IonFooter,
    IonModal,
    IonToolbar,
    IonList,
    IonGrid,
    IonSpinner,
    IonIcon,
    NativeLink,
    IonCheckbox,
    IonInput,
    IonItem,
    IonLabel,
    IonCol,
    IonSlides,
    IonSlide,
    IonRow,
    IonNote,
    IonText,
    IonButton,
  },
  ...caph,
  props: {
    isOnLogin: {
      type: Boolean,
    },
    isOnSubscribe: {
      type: Boolean,
    },
    isShowLogin: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();

    const ionRouter: any = inject("navManager");
    const isWeb = ref(isWebPlatform());
    const IsTestFlightEnabled = ref(store.getters["cache/IsTestFlightEnabled"]);
    const cred = ref({
      email: "",
      password: "",
      passwordConfirm: "",
    });

    const emailLogin = ref(null);
    const emailSubscribe = ref(null);
    const passSubscribe = ref(null);
    const passConfirmSubscribe = ref(null);

    const refNameToRef: any = (refName: any) => {
      let ref: any;
      switch (refName) {
        case "email-login":
          ref = emailLogin.value;
          break;
        case "email-subscribe":
          ref = emailSubscribe.value;
          break;
        case "confirm-pass-subscribe":
          ref = passConfirmSubscribe.value;
          break;
        default:
          ref = passSubscribe.value;
          break;
      }
      return ref;
    };

    const validationsPassword = [
      // {
      //   title: 'At least 8 characters',
      //   predicate: (input) => input.length >= 8,
      // },
      // {
      //   title: 'Consists of numbers and letters',
      //   predicate: (input) => /\d.*[a-zA-Z]|[a-zA-Z].*\d/.test(input),
      // },
      // {
      //   title: 'Contains upper and lower case letters',
      //   predicate: (input) => /[a-z].*[A-Z]|[A-Z].*[a-z]/.test(input),
      // },
      {
        title:
          "Your password length should be 8 or more, with lowercase, uppercase, and symbols",
        predicate: (input: string) =>
          input.length >= 8 &&
          /\d.*[a-zA-Z]|[a-zA-Z].*\d/.test(input) &&
          /[a-z].*[A-Z]|[A-Z].*[a-z]/.test(input),
      },
      {
        title: "Passwords must match",
        predicate: (input: string) => input === cred.value.passwordConfirm,
      },
    ];

    const validateEmail = (value: string) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value?.toLowerCase()
      );
    const validateEmailInput = (ev: { target: { value: any; }; }, refName: any) => {
      const value = ev.target.value;

      refNameToRef(refName).$el.classList.remove("ion-invalid");

      if (value === "") return;

      validateEmail(value)
        ? refNameToRef(refName).$el.classList.add("ion-valid")
        : refNameToRef(refName).$el.classList.add("ion-invalid");
    };
    const markTouched = (refName: any) => {
      refNameToRef(refName).$el.classList.add("ion-touched");
    };
    const validatePass = (pass: string) => {
      return validationsPassword.every((_) => _.predicate(pass));
    };
    const validatePassInput = (ev: { target: { value: any; }; }, refName: any) => {
      const value = ev.target.value;

      refNameToRef(refName).$el.classList.remove("ion-valid");
      refNameToRef(refName).$el.classList.remove("ion-invalid");

      if (value === "") return;

      validatePass(value)
        ? refNameToRef(refName).$el.classList.add("ion-valid")
        : refNameToRef(refName).$el.classList.add("ion-invalid");
    };
    const validatePassMessage = () => {
      return (
        validationsPassword.find((_) => !_.predicate(cred.value.password))
          ?.title || ""
      );
    };

    const isChecked = ref(false);

    const login = () => {
      context.emit("login", true);
    };

    const subscribe = () => {
      context.emit("subscribe", true);
    };
    const close = () => {
      context.emit("close");
    };

    onIonViewWillEnter(async () => {
      try {
        document
          .getElementById("ion-app")!
          .classList.add("with-desktop-header");
        const t = await initializeStore();
        store.dispatch(
          "cache/setSubscribePrice",
          (t as any[]).find((product) => product.productId === PRODUCT_ID).price
        );
      } catch (e) {
        console.error(e);
      }
    });

    onIonViewDidEnter(async () => {
      if (router.currentRoute.value.query.delete) {
        const toast = await toastController.create({
          message:
            "Thank you. Your account has been sent for review, and will be processed in 72 hours.",
          duration: 2000,
          color: "warning",
          position: "middle",
        });
        return toast.present();
      }
    });

    const isLoading = ref(false);
    const isPasswordShow = ref(false);
    const isPasswordFocus = ref(false);

    const loginUser = async () => {
      let isSuccess = true;
      let errorLog = "";
      const email = cred.value.email + "";

      if (isDisabled.value) {
        return;
      }
      isLoading.value = true;
      await auth
        .login(cred.value)
        .then(async (res) => {
          if (res.data) {
            const data = res.data;
            if (auth) {
              await setClientInfo(data);
              cred.value = {
                email: "",
                password: "",
                passwordConfirm: "",
              };
              close();
              ionRouter.navigate({
                routerDirection: "forward",
                routerLink: "/",
              });
              nextTick(() => {
                eventBus().emitter.emit("route-update");
              });
            }
          }
        })
        .catch(async (err) => {
          const toast = await toastController.create({
            message: "Incorrect username or password",
            duration: 2000,
            color: "danger",
          });
          isSuccess = false;
          errorLog = err;
          return toast.present();
        });
      isLoading.value = false;
      await logEvent("LOGIN_SUBMIT", {
        isSuccess,
        errorLog,
        email,
      });
    };

    const onCheckedChange = (event: { detail: { checked: boolean; }; }) => {
      isChecked.value = event.detail.checked;
    };

    const onFocus = () => {
      isPasswordFocus.value = true;
    };

    const offFocus = () => {
      isPasswordFocus.value = false;
    };

    const togglePasswordShow = () => {
      isPasswordShow.value = !isPasswordShow.value;
    };

    const isDisabled = computed(() => {
      return props.isOnLogin
        ? !cred.value.password || !validateEmail(cred.value.email)
        : !validatePass(cred.value.password) ||
        !validateEmail(cred.value.email) ||
        !isChecked.value ||
        cred.value.password !== cred.value.passwordConfirm;
    });

    const goToPass = (postfix: string) => {
      (document.getElementById("input-password-" + postfix) as any).setFocus();
    };

    watch(
      () => props.isOnLogin,
      async () => {
        await nextTick();
        (document.getElementById("input-email-login") as any)?.setFocus();
        props.isOnLogin
          ? await logEvent("POPUP_LOGIN_OPEN")
          : await logEvent("POPUP_LOGIN_CLOSE");
      },
      {
        flush: "post",
      }
    );

    watch(
      () => props.isOnSubscribe,
      async () => {
        await nextTick();
        (document.getElementById("input-email-subscribe") as any)?.setFocus();
        props.isOnLogin
          ? await logEvent("POPUP_SUBSCRIBE_OPEN")
          : await logEvent("POPUP_SUBSCRIBE_CLOSE");
      },
      {
        flush: "post",
      }
    );
    onIonViewWillEnter(() => {
      document.getElementById("ion-app")!.classList.add("with-desktop-header");
    });

    const setClientInfo = (payload: any) =>
      store.dispatch("auth/setClientInfo", payload);


    const showSuccessToast = async (message: string): Promise<void> => {
      const toast = await toastController.create({
        message,
        duration: 2000,
        color: "success",
      });
      return toast.present();
    };

    const showErrorToast = async (message: string): Promise<void> => {
      const toast = await toastController.create({
        message,
        duration: 2000,
        color: "danger",
      });
      return toast.present();
    };

    const handleSubscriptionProcess = async (subscriptionExists: boolean, cred: Ref<{ email: string; password: string; passwordConfirm: string; }>, isDisabled: ComputedRef<boolean>, isLoading: Ref<boolean>): Promise<void> => {
      try {
        if (subscriptionExists) {
          // Existing subscription workflow
          const { data: { user } } = await auth.signup(cred.value);
          console.log("User", user);

          if (user) {
            const result = await processWooCommercePhase(user, cred, isDisabled, isLoading);
            if (result) {
              await showSuccessToast("Success");
            }
          }

        } else {
          // New subscription workflow
          const subscriptionTransactionInfo = await storeBuySubscription();
          console.log("subscriptionTransactionInfo:", subscriptionTransactionInfo);
          const { success } = await validateSubscription(subscriptionTransactionInfo);

          console.log("Success?", success);

          if (success) {
            const { data: { user } } = await auth.signup(cred.value);

            if (user) {
              const result = await processWooCommercePhase(user, cred, isDisabled, isLoading);

              console.log("processWooCommercePhase result", result);

              if (result) {
                return await showSuccessToast("Success");
              }
            }
          }

          isLoading.value = false
          return await showErrorToast("Transaction validation error");
        }
      } catch (error) {
        console.error("Error during subscription process", error);
        throw error;
      }
    };

    const processWooCommercePhase = async (user: any, cred: { value: Record<string, any>; }, isDisabled: { value: any; }, isLoading: { value: boolean; }): Promise<boolean> => {
      console.log('processWooCommercePhase props', user, isDisabled, cred, isLoading)
      try {
        console.log("WooCommerce phase...");

        // Sync WooCommerce subscription
        const woocommerceSubscription = await processSubscription({ userId: user.id });
        console.log("woocommerceSubscription", woocommerceSubscription);

        // Log in user
        if (isDisabled.value) {
          return false;
        }

        await auth.login(cred.value).then(async ({ data }) => {
          if (data) {
            if (auth) {
              await setClientInfo(data);
              close();
              cred.value = {
                email: "",
                password: "",
                passwordConfirm: "",
              };
              ionRouter.navigate({
                routerDirection: "forward",
                routerLink: "/home?welcome=1",
              });
              nextTick(() => {
                eventBus().emitter.emit("route-update");
              });
            }
          }
        });

        isLoading.value = false;
        return true;
      } catch (error) {
        console.error("Error in WooCommerce phase", error);
        return false;
      }
    };

    const sendPurchaseAnalyticEvent = async (): Promise<void> => {
      const price = store.state.subscribeInfo.price;
      const info = {
        key: "af_subscribe",
        value: {
          af_revenue: price.replace(/[^\d.-]/g, ""),
          af_currency: price.replace(/[0-9]/g, ""),
        },
      };
      await analyticsManager.sendAnalytics(info);
    }

    const signUpAndSubscribe = async () => {
      isLoading.value = true;
      let isSuccess = true;
      const email = cred.value.email + "";
      let errorLog: unknown;

      try {
        console.log("Starting the subscription process...");

        // Check if subscription exists
        const subscriptionExists = await checkSubscription();
        console.log(subscriptionExists ? "Existing subscription found." : "No existing subscription.");

        // Handle subscription process based on existence of subscription
        await handleSubscriptionProcess(subscriptionExists, cred, isDisabled, isLoading);

      } catch (error) {
        console.log("Subscription processing error:", error);
        isSuccess = false;
        errorLog = error;
        await showErrorToast("Try again");
      } finally {
        isLoading.value = false;
      }

      await sendPurchaseAnalyticEvent();
      console.log('sendPurchaseAnalyticEvent')
      await logEvent("SUBSCRIBE_SUBMIT", {
        isSuccess,
        errorLog,
        email,
      });
    };

    return {
      router,
      isChecked,
      isLoading,
      close,
      login,
      subscribe,
      isWeb,
      cred,
      IsTestFlightEnabled,
      onCheckedChange,
      signUpAndSubscribe,
      goToPass,
      isDisabled,
      chevronBackOutline,
      eyeOutline,
      eyeOffOutline,
      isPasswordShow,
      alertCircleOutline,
      togglePasswordShow,
      isPasswordFocus,
      onFocus,
      loginUser,
      validationsPassword,
      offFocus,
      validateEmailInput,
      validatePassInput,
      validatePassMessage,
      validateEmail,
      validatePass,
      markTouched,
      emailLogin,
      emailSubscribe,
      passSubscribe,
      passConfirmSubscribe,
      store,
    };
  },
});
</script>
<style scoped>
#gateway {
  overflow: hidden !important;
  max-width: 100vh;
  clip: rect(auto, auto, auto, auto);
}

.form .title-form {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin-top: 74px;
}

ion-note {
  --color: #fff;
}

.auth-form ion-item ion-input {
  --placeholder-color: #fff;
  --color: #fff;
}

.auth-form ion-item ion-label {
  --color: #6c89e4 !important;
  --ion-color-primary: #6c89e4 !important;
}

.terms {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  text-align: center;
  color: #cacaca;
  cursor: pointer;
  display: block;
  margin: 50px auto 0;
}

.terms strong {
  font-weight: 500;
}

.close-modal {
  font-size: 26px;
  opacity: 0.6;
  position: absolute;
  top: 70px;
  left: 30px;
  transform: rotate(0deg);
  cursor: pointer;
  color: #fff;
}

.button-form {
  text-transform: none;
  font-size: 16px;
  font-weight: 800;
  height: 50px;
  margin: 0 auto 0px auto;
  --background: #fcb814 !important;
  --background-hover: #fcb814 !important;
  --background-activated: #fcb814 !important;
  --background-focused: #fcb814 !important;
  --box-shadow: unset;
  --color: #171a2b;
  z-index: 100;
  margin-top: 34px;
  z-index: 100;
  display: block;
  transition: --opacity unset !important;
  --border-radius: 10px;
}

.auth-form ion-item {
  --background: #171a2b !important;
  --ion-item-background: #171a2b !important;
  --color: #fff !important;
}

.checkbox-item {
  margin-top: 12px;
  --ripple-effect: none;
}

.checkbox-item ion-checkbox {
  --size: 14px;
  margin-right: 10px;
  --background: #171a2b;
  --background-checked: #fff;
  --border-color: #171a2b;
  --ripple-color: #171a2b;
  --checkmark-color: #171a2b;
  --checkmark-width: 3px;
}

.checkbox-item .label {
  font-size: 12px;
}

.checkbox-item ion-checkbox::part(container) {
  border-radius: 2px;
  border: 1px solid #fff;
}

.form {
  width: 90%;
  margin-left: 5%;
}

.eye-wrapper {
  position: relative;
}

ion-note {
  --highlight-color-invalid: #f83206;
}

.error-wrapper {
  display: flex;
  column-gap: 9px;
  align-items: center;
  margin-top: 3px;
}

.error-wrapper ion-icon {
  font-size: 14px;
  min-width: 16px;
}

.error-wrapper span {
  font-size: 12px;
  line-height: 12px;
}

ion-note ion-icon,
ion-note span {
  display: block;
}

ion-icon.eye {
  --color: #fff !important;
  color: #fff !important;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateX(-50%);
  z-index: 10;
  --font-size: 16px;
}

.login-button {
  text-transform: none;
  font-size: 16px;
  font-weight: 800;
  --border-color: #fff;
  --color: #fff;
}

ion-input,
ion-label,
ion-checkbox,
ion-item,
ion-item div.label {
  --color: #fff !important;
}

.free-button {
  text-transform: none;
  font-size: 16px;
  font-weight: 800;
  --background: #f8b518 !important;
  --background-hover: #f8b518 !important;
  --background-activated: #f8b518 !important;
  --background-focused: #f8b518 !important;
}

.subscribe-button {
  text-transform: none;
  font-size: 16px;
  font-weight: 800;
  --background: transparent !important;
  --background-hover: transparent !important;
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --border-color: #f8b518;
  --border-width: 1.5px;
  --color: #fff;
  --opacity: 1 !important;
}

.login-span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  margin: 24px auto;
  display: block;
}

.login-span.forgot {
  text-align: right;
  display: block;
  margin-right: 12px;
}

.login-span strong {
  color: #f8b518;
}

.buttons ion-button {
  width: 240px;
  height: 44px;
}

.buttons {
  position: absolute;
  bottom: 69px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  column-gap: 19px;
  align-items: center;
  z-index: 1;
}

@media screen and (min-width: 960px) {
  ion-button {
    width: 250px;
    border-radius: 25px;
  }

  .terms {
    display: none;
  }

  .login-span {
    color: #fff;
    margin-top: 70px;
    display: block;
    text-align: center;
    margin-left: 14px;
  }

  .login-span.forgot {
    text-align: right;
    display: block;
    margin-right: 0;
    margin-top: 18px;
  }

  .buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    column-gap: 36px;
    align-items: center;
    margin-bottom: 120px;
    z-index: 1;
  }

  .free-button,
  .subscribe-button,
  .login-button {
    height: 67px;
  }

  .form {
    width: 460px;
    margin: 0 auto;
  }

  .form .title-form {
    font-size: 40px;
    line-height: 40px;
    white-space: nowrap;
    margin-bottom: 16px;
  }

  .close-modal {
    font-size: 36px;
    opacity: 1;
    position: absolute;
    top: 24px;
    left: 60px;
    right: auto;
    transform: rotate(-180deg);
    cursor: pointer;
  }
}
</style>
