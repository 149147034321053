<template>
  <div class="timer">
    <circle-progress v-if="percentage >= 0 && percentage <= 100" :size="40" :percentage="percentage" :strokeWidth="2" ringColor="#fff" progressColor="#5863F8"/>
    <div v-if="percentage >= 0 && percentage <= 100" class="time-left">{{ timeLeft }}</div>
    <ion-icon v-else :icon="stopwatchOutline"/>
  </div>
</template>
<script lang="ts">
import { IonIcon } from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import { stopwatchOutline } from "ionicons/icons";

import CircleProgress from 'vue-svg-circle-progress';

import { useStore } from "vuex";

import { secondsToHms } from "@/helpers/secondToHms";

export default defineComponent({
  name: "Timer",
  components: { IonIcon, CircleProgress },
  setup() {
    const store = useStore();
    const totalSteps = computed(() => store.state.cache.timer.timeout);
    const now = ref(new Date().getTime());
    const interval = setInterval(() => {
      now.value = new Date().getTime();
    }, 1000);
    const completedSteps = computed(() => (store.state.cache.timer.ts - now.value) / 10);
    const timeLeft = computed(() => secondsToHms(+((store.state.cache.timer.ts - now.value) / 1000).toFixed(0)));
    return { 
      stopwatchOutline,
      completedSteps,
      totalSteps,
      now,
      timeLeft,
      percentage: computed(() => completedSteps.value/totalSteps.value),
      interval,
    };
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  ionViewWillLeave() {
    clearInterval(this.interval);
  }
});
</script>
<style>
.timer {
  height: 40px;
  width: 40px;
  position: relative;
  cursor: pointer;
}

.timer .time-left {
  font-size: 9px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timer ion-icon {
  font-size: 24px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timer .circle-progress:after {
  display: none;
  content: '';
}
</style>
