import { createStore } from 'vuex'
import auth from './modules/auth'
import cache from './modules/cache';
import createPersistedState from "vuex-persistedstate";

export const store = createStore({
    modules: {
        auth,
        cache,
    },
    plugins: [createPersistedState()], 
});