import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43c22168"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "desktop-header"
}
const _hoisted_2 = { class: "wrapper-header" }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "links",
  key: 1
}
const _hoisted_6 = {
  class: "search",
  key: 2
}
const _hoisted_7 = { class: "searchbar-wrapper" }
const _hoisted_8 = { class: "actions" }
const _hoisted_9 = {
  class: "menu",
  key: 3
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_free_timer = _resolveComponent("free-timer")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_timer = _resolveComponent("timer")!
  const _component_menu_info = _resolveComponent("menu-info")!

  return (_ctx.isShowDesktopHeader)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isWebPlatform())
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "logo",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLogoClick && _ctx.onLogoClick(...args)))
                }, [
                  _createElementVNode("img", {
                    src: require('@/assets/img/full-logo.svg')
                  }, null, 8, _hoisted_4),
                  _createVNode(_component_free_timer)
                ]))
              : _createCommentVNode("", true),
            (_ctx.router.currentRoute.value.name!=='home' && !_ctx.isWebPlatform())
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "back-button",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.router.back()))
                }, [
                  _createVNode(_component_ion_icon, { icon: _ctx.chevronBackOutline }, null, 8, ["icon"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_Transition, {
              name: "fade-desk",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (!_ctx.isSearchShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_router_link, {
                        focusable: "",
                        onCaph: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/category/shorts/'))),
                        to: '/category/shorts/'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Shorts")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_router_link, {
                        focusable: "",
                        onCaph: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/category/movies/'))),
                        to: '/category/movies/'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Movies")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_router_link, {
                        focusable: "",
                        onCaph: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push('/explore/'))),
                        to: '/explore/'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Explore")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_icon, {
                        focusable: "",
                        class: "search-icon",
                        icon: _ctx.searchOutline,
                        onCaph: _ctx.onSearchClick,
                        onClick: _ctx.onSearchClick
                      }, null, 8, ["icon", "onCaph", "onClick"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_ion_searchbar, {
                          id: "searchbar-desktop",
                          style: {"color":"white"},
                          debounce: "300",
                          placeholder: "Search show titles",
                          value: _ctx.searchText,
                          enterkeyhint: "search",
                          inputmode: "search",
                          type: "search",
                          onKeyup: _withKeys(_ctx.onSearchEnter, ["enter"]),
                          onIonChange: _ctx.onSearchTextChange,
                          onIonClear: _ctx.onSearchClear,
                          "show-cancel-button": "never",
                          "show-clear-button": "never"
                        }, null, 8, ["value", "onKeyup", "onIonChange", "onIonClear"]),
                        _createVNode(_component_ion_back_button, {
                          class: "search-clear",
                          mode: "ios",
                          focusable: "",
                          icon: _ctx.closeOutline,
                          "default-href": "/home",
                          onClick: _ctx.onSearchClear,
                          text: ""
                        }, null, 8, ["icon", "onClick"])
                      ]),
                      _createElementVNode("div", {
                        class: "button-search",
                        focusable: "",
                        onCaph: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onSearchEnter && _ctx.onSearchEnter(...args))),
                        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onSearchEnter && _ctx.onSearchEnter(...args)))
                      }, " Search ", 32)
                    ]))
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_timer, {
                focusable: "",
                onCaph: _ctx.onTimerClick,
                onClick: _ctx.onTimerClick
              }, null, 8, ["onCaph", "onClick"]),
              _createVNode(_component_ion_icon, {
                class: "menu-icon",
                icon: _ctx.menuOutline,
                focusable: "",
                onCaph: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isMenuShow = true)),
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isMenuShow = true))
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        (_ctx.isMenuShow)
          ? (_openBlock(), _createElementBlock("div", {
              class: "menu-background",
              key: 4,
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isMenuShow = false))
            }))
          : _createCommentVNode("", true),
        _createVNode(_Transition, { name: "menu" }, {
          default: _withCtx(() => [
            (_ctx.isMenuShow)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_ion_icon, {
                    class: "close",
                    icon: _ctx.closeOutline,
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.isMenuShow = false))
                  }, null, 8, ["icon"]),
                  _createVNode(_component_menu_info)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}