<template>
<div class="desktop-header" v-if="isShowDesktopHeader">
  <div class="wrapper-header">
    <div class="wrapper">
      <div class="logo" v-if="isWebPlatform()" @click="onLogoClick">
        <img :src="require('@/assets/img/full-logo.svg')"/>
        <free-timer/>
      </div>
      <div class="back-button" v-if="router.currentRoute.value.name!=='home' && !isWebPlatform()" @click="router.back()">
        <ion-icon :icon="chevronBackOutline"></ion-icon>
      </div>
      <transition name="fade-desk" mode="out-in">
        <div class="links" v-if="!isSearchShow" :key="1">
          <router-link focusable @caph="$router.push('/category/shorts/')" :to="'/category/shorts/'">Shorts</router-link>
          <router-link focusable @caph="$router.push('/category/movies/')" :to="'/category/movies/'">Movies</router-link>
          <router-link focusable @caph="$router.push('/explore/')" :to="'/explore/'">Explore</router-link>
          <ion-icon focusable class="search-icon" :icon="searchOutline" @caph="onSearchClick" @click="onSearchClick"/>
        </div>
        <div class="search" v-else :key="2">
          <div class="searchbar-wrapper">
            <ion-searchbar
              id="searchbar-desktop"
              style="color: white"
              debounce="300"
              placeholder="Search show titles"
              :value="searchText"
              enterkeyhint="search"
              inputmode="search"
              type="search"
              @keyup.enter="onSearchEnter"
              @ionChange="onSearchTextChange"
              @ionClear="onSearchClear"
              show-cancel-button="never"
              show-clear-button="never"
            />
            <ion-back-button class="search-clear" mode="ios" focusable :icon="closeOutline" default-href="/home" @click="onSearchClear" text=""/>
          </div>
          <div class="button-search" focusable @caph="onSearchEnter" @click="onSearchEnter">
            Search
          </div>
        </div>
      </transition>
      <div class="actions">
        <timer focusable @caph="onTimerClick" @click="onTimerClick"/>
        <ion-icon class="menu-icon" :icon="menuOutline" focusable @caph="isMenuShow = true" @click="isMenuShow = true"/>
      </div>
    </div>
  </div>
  <div class="menu-background" v-if="isMenuShow" :key="4" @click="isMenuShow = false"/>
  <transition name="menu">
    <div class="menu" v-if="isMenuShow" :key="3">
      <ion-icon class="close" :icon="closeOutline" @click="isMenuShow = false"/>
      <menu-info/>
    </div>
  </transition>
</div>
</template>
<script lang="ts">
import { watch, defineComponent, ref, inject, computed } from 'vue';
import {
  IonIcon,
  IonRange,
  IonSearchbar,
  IonBackButton,
} from "@ionic/vue";

import { useStore } from "vuex";

import Timer from "./Timer.vue";
import FreeTimer from './FreeTimer.vue'

import { searchOutline, menuOutline, closeOutline, chevronBackOutline } from "ionicons/icons";
import MenuInfo from './MenuInfo.vue';
import { useRouter } from 'vue-router';

import eventBus from '@/helpers/event-bus';
import { isWebPlatform } from '@/helpers/orientation';

export default defineComponent({
  components: {
    IonIcon,
    IonRange,
    Timer,
    FreeTimer,
    MenuInfo,
    IonSearchbar,
    IonBackButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const ionRouter: any = inject("navManager");

    const isSearchShow = ref(false);
    const isSearchNavigated = ref(false);
    const isMenuShow = ref(false);
    const value = ref(0);
    const searchText = ref(router.currentRoute.value.query.search);
    const isPreventHeaderShow = ref(false);

    const isShowDesktopHeader = computed(() => {
      return !['auth', 'login', 'register'].includes(router.currentRoute.value.name?.toString() || '') && !isPreventHeaderShow.value;
    })

    const toggleDesktopHeader = (flag) => {
      isPreventHeaderShow.value = flag;
    }

    watch(() => router.currentRoute.value, (val, oldVal) => {
      searchText.value = router.currentRoute.value.query.search;
      if (val.name !== oldVal.name) {
        isMenuShow.value = false;
        if (val.name !== 'search') {
          isSearchShow.value = false;
        }
      }
    });
    const onTimerClick = () => {
      eventBus().emitter.emit('timer-settings', true);
    }

    eventBus().emitter.on('timer-settings', toggleDesktopHeader);

    const onChange = (e: any) => {
      value.value = e.detail.value;
    }
    
    const onSearchTextChange = (e: any) => {
      searchText.value = e.target.value;
    }

    const onSearchEnter = () => {
      eventBus().emitter.emit('search', searchText.value);
    } 

    const onSearchClick = () => {
      router.push({ name: 'search' });
      isSearchNavigated.value = true;
      isSearchShow.value = true;
    };

    const onSearchClear = () => {
      if (isSearchNavigated.value) {
        router.go(-1);
        isSearchNavigated.value = false;
      }
      isSearchShow.value = false;
    }

    const onLogoClick = () => {
      ionRouter.navigate({
        routerDirection: "back",
        routerLink: { name: 'home' },
      });
      isSearchShow.value = false;
    }

    return {
      searchOutline,
      menuOutline,
      closeOutline,
      isMenuShow,
      onTimerClick,
      isPreventHeaderShow,
      onChange,
      value,
      onSearchClick,
      onSearchTextChange,
      onSearchClear,
      isSearchShow,
      searchText,
      onSearchEnter,
      onLogoClick,
      router,
      isShowDesktopHeader,
      chevronBackOutline,
      isWebPlatform,
    };
  },
});
</script>
<style scoped>
.menu-enter-active, .menu-leave-active {
  transition: all .3s ease-in-out;
  transform: translateX(0);
}
.menu-enter, .menu-leave-to {
  transform: translateX(100%);
}

.fade-desk-enter-active, .fade-desk-leave-active {
  transition: opacity .3s ease-in-out;
}
.fade-desk-enter, .fade-desk-leave-to {
  opacity: 0;
}

.desktop-header {
  z-index: 1000;
  position: fixed;
  width: 100vw;
}

.desktop-header a {
  text-decoration: none;
  color: #fff;
}

.desktop-header .wrapper-header {
  padding: 25px;
}

.desktop-header .wrapper {
  height: 48px;
  position: relative;
}

.desktop-header .wrapper .logo {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 8px;
}

.desktop-header .back-button {
  cursor: pointer;
}

.desktop-header .back-button ion-icon {
  font-size: 48px;
  --color: white;
  color: white;
}

.desktop-header .wrapper .logo img {
  height: 32px;
}

.menu-icon {
  padding: 9px;
  border-radius: 22px;
  transition: all .2s ease-in-out;
}

.timer {
  border-radius: 22px;
  transition: all .2s ease-in-out;
}

.desktop-header .wrapper .links {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  column-gap: 45px;
  align-items: center;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
}

.desktop-header .wrapper .links a {
  padding: 4px 12px;
  border-radius: 18px;
  transition: all .2s ease-in-out;
}

.desktop-header .wrapper .links .search-icon {
  font-size: 24px;
  cursor: pointer;
  color: #fff;
  padding: 10px;
  border-radius: 22px;
  transition: all .2s ease-in-out;
}

.desktop-header .wrapper .search {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.desktop-header .wrapper .search ion-searchbar {
  --background: #30304e !important;
  --border-radius: 50px !important;
  --icon-color: #9595ad !important;
  height: 40px;
  padding: 0;
  z-index: 1;
  transition: all 0.3s;
  min-width: 320px;
}

.desktop-header .wrapper .search .button-search {
  white-space: nowrap;
  border: 1px solid #658AEB;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 10px 31px;
  color: #658AEB;
  height: 40px;
  text-align: center;
  cursor: pointer;
}

.desktop-header .wrapper .search .searchbar-wrapper {
  position: relative;
}

.desktop-header .wrapper .search .searchbar-wrapper .search-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 1;
  color: #fff !important;
}

.desktop-header .wrapper .actions {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  column-gap: 25px;
  align-items: center;
}

.desktop-header .wrapper .actions ion-icon {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.desktop-header .wrapper .actions .timer-range {
  width: 280px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  animation: fade .3s ease-in-out;
}

.desktop-header .wrapper .actions .timer-range .label-timer {
  white-space: nowrap;
  border: 1px solid #658AEB;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 6px 14px;
  color: #658AEB;
  height: 32px;
  min-width: 80px;
  text-align: center;
  cursor: pointer;
}


.desktop-header .wrapper .actions .timer-range ion-range {
  --bar-background: #30304E;
  --bar-background-active: #658AEB;
  --bar-border-radius: 2px;
  --bar-height: 4px;
  --pin-background: transparent;
  --knob-background: #0B132B;
  --knob-size: 24px;
  --knob-box-shadow: 0px 0px 0px 3px #658AEB inset;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.menu-background {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #0B132B;
  opacity: .5;
  animation: fade .4s ease-in-out;
}

.menu ion-icon.close {
  position: absolute;
  right: 25px;
  top: 38px;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
}

.menu {
  z-index: 1000;
  background: #000;
  height: 100vh;
  width: 400px;
  position: fixed;
  top: 0;
  right: 0;
  animation: menu .3s ease-in-out;
}


@keyframes menu {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}


@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: .5;
  }
}

.menu .menu-info {
  margin-top: 60px;
}

.desktop-header {
  display: none;
}

@media screen and (min-width: 960px) {
  .desktop-header {
    display: block;
  }
}
</style>
